@media print {
	.atpv-print-box {
		display: flex !important;
		flex-direction: row !important;
		margin-bottom: 1px !important;
	}
	.atpv-print-width {
		width: 50% !important;
	}
	.atpv-print-border-right {
		border-right: 2px dotted #434041 !important;
	}
	.atpv-print-margin-left {
		margin-left: 5px !important;
	}
	.atpv-print-title {
		font-size: small !important;
	}
	div {
		margin-top: 0 !important;
	}
	input {
		font-size: 12px !important;
	}
	p {
		font-size: medium !important;
	}
}

.red-star::before {
	content: '*';
	color: red;
	margin-right: 4px;
}
