@media print {
	.crv-print-width{
		width: 90% !important;
	}
	.crv-print-box {
		display: flex !important;
		flex-direction: row !important;
	}
	.crv-print-font-size {
		font-size: medium !important;
	}
}

.red-star::before {
	content: '*';
	color: red;
	margin-right: 4px;
}
