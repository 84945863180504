.image__login div{
    position: absolute !important;
    right: -60px;
    width: 60px;
    height: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

#first-color-rectangle{
    background-color: #D9E32C;
    top: 40px;
}

#second-color-rectangle{
    background-color: #7EC751;
    top: 75px;
}

#third-color-rectangle{
    background-color: #13B047;
    top: 110px;
}

#fourth-color-rectangle{
    background-color: #00B0BC;
    top: 145px;
}
