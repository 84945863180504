html, body, #root {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
}

.App-main {
	height: 100vh;
	width: 100%;
}

hr {
	width: 100%;
	height: 5px;
	margin: 50px 0;
	background: -webkit-gradient(linear, 0 0, 100% 0, from(rgba(0, 0, 0, 0)), color-stop(0.5, #00B0BC), to(rgba(0, 0, 0, 0)));
	background: -webkit-linear-gradient(to left, rgba(0, 0, 0, 0), #00B0BC, rgba(0, 0, 0, 0));
	background: -moz-linear-gradient(to left, rgba(0, 0, 0, 0), #00B0BC, rgba(0, 0, 0, 0));
	background: -o-linear-gradient(to left, rgba(0, 0, 0, 0), #00B0BC, rgba(0, 0, 0, 0));
	background: linear-gradient(to left, rgba(0, 0, 0, 0), #00B0BC, rgba(0, 0, 0, 0));
	border: 0;
}
hr:after {
	display: block;
	content: '';
	height: 5px;
	background-image: -webkit-gradient(radial, 50% 0%, 0, 50% 0%, 116, color-stop(0%, #59cedd), color-stop(100%, rgba(255, 255, 255, 0)));
	background-image: -webkit-radial-gradient(center top, farthest-side, #59cedd 0%, rgba(255, 255, 255, 0) 100%);
	background-image: -moz-radial-gradient(center top, farthest-side, #59cedd 0%, rgba(255, 255, 255, 0) 100%);
	background-image: -o-radial-gradient(center top, farthest-side, #59cedd 0%, rgba(255, 255, 255, 0) 100%);
	background-image: radial-gradient(farthest-side at center top, #59cedd 0%, rgba(255, 255, 255, 0) 100%);
}

@media print {
	html,
	body {
		height: initial !important;
		overflow: initial !important;
		-webkit-print-color-adjust: exact;
	}
	.page-break {
		margin-top: 1rem;
		display: block;
		page-break-before: auto;
	}
}
